import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';
import organizationProp from '../../../../commonComponents/propTypes/organizationProp';
import FormInput from '../../../../commonComponents/forms/FormInput';

const convertOpportunities = (organization, value) => {
  return {
    ...(organization?.settings?.opportunities || {}),
    shouldCreateOrderWhenApproved: value,
  };
};
function TabOpportunities(props) {
  const { t, organization } = props;

  const [shouldCreateOrderWhenApproved, setShouldCreateOrderWhenApproved] =
    useState(
      organization?.settings?.opportunities?.shouldCreateOrderWhenApproved ||
        false,
    );

  useEffect(() => {
    const opportunities = convertOpportunities(
      organization,
      organization?.settings?.opportunities?.shouldCreateOrderWhenApproved,
    );
    $('#opportunities').val(JSON.stringify(opportunities));
  }, []);

  const handleCheckbox = () => {
    setShouldCreateOrderWhenApproved(prev => {
      const opportunities = convertOpportunities(organization, !prev);
      $('#opportunities').val(JSON.stringify(opportunities));
      return !prev;
    });
  };

  return (
    <div id="tabOpportunities">
      <h4 className="title">{t('opportunities')}</h4>
      <FormInput
        type="checkbox"
        id="shouldCreateOrderWhenApproved"
        name="shouldCreateOrderWhenApproved"
        label={t('shouldCreateOrderWhenApproved')}
        hint={t('shouldCreateOrderWhenApproved_hint')}
        value={shouldCreateOrderWhenApproved}
        onChange={handleCheckbox}
        labelClassName="col-4"
        containerClassName="col-4"
        inputClassName="opportunitiesCheckbox"
        groupProps={{ className: 'row' }}
      />
    </div>
  );
}

TabOpportunities.propTypes = {
  t: PropTypes.func.isRequired,
  organization: PropTypes.shape(organizationProp.propType).isRequired,
};
TabOpportunities.defaultProps = {};

export default translate(TabOpportunities);
